import styled, { css } from "styled-components"

const Button = styled.button`
    display: inline-block;
    background-color: var(--color-white);
    color: var(--color-black);
    font-family: var(--font-sans);
    font-size: 0.875em;
    font-weight: 600;
    letter-spacing: 0.025em;
    border: 0;
    line-height: 1.5;
    margin: 0;
    user-select: none;
    line-height: 2.3;
    text-decoration: none;
    cursor: pointer;
    padding: 0 1em;
    height: 2.5em;
    border: 0;
    white-space: nowrap;
    transition: color 0.1s ease, background-color 0.1s ease;
    box-shadow: 0 0.125em 0.5em -0.25em rgba(0,0,0,0.2);
    .can-hover &:hover,
    &:active {
      background-color: var(--color-gray);
      color: var(--color-white);
    }
    svg {
      display: inline-block;
      vertical-align: middle;
    }
    svg:first-child {
      margin-left:-0.25em;
      margin-right: 0.125em;
    }
    svg:last-child {
      margin-right: -0.25em;
    }
    span {
        display: inline-block;
        vertical-align: middle;
    }
    ${props => props.theme === 'dark' && css`
      background-color: var(--color-black);
      color: var(--color-white);
    `}
`

export default Button