import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import IconFacebook from "components/icons/facebook"
import IconInstagram from "components/icons/instagram"
import IconTwitter from "components/icons/twitter"

const Footer = props => {
  
  const data = useStaticQuery(
    graphql`
      query {
        globals: directusGlobal {
          site_title
          social_name
          social_facebook
          social_instagram
          social_twitter
          contact_email
          copyright_text
          footer_text
          footer_title
        }
      }
    `
  )
  
  const globals = data.globals

  const socials = ['Twitter', 'Facebook', 'Instagram', 'Youtube'].filter(( social ) => globals[`social_${social.toLocaleLowerCase()}`])

  const getIcon = ( slug ) => {
    switch (slug.toLowerCase()) {
      case 'twitter': 
        return <IconTwitter />
      case 'facebook': 
        return <IconFacebook />
      case 'instagram': 
        return <IconInstagram />
      default:
        return <></>
    }
  }
  
  return (
    <StyledFooter>
      {/*
      <h3>{globals.footer_title}</h3>
      <div>
        <p>{globals.footer_text}</p>
      </div>
      */}
      <div>
        <StyledSocialLinks>
          {socials.map((social) => (
            <a 
              key={`footer-social-${social}`}
              href={globals[`social_${social.toLocaleLowerCase()}`]} 
              target="_blank" 
              rel="noopener noreferrer" 
              title={`${globals.social_name} on ${social}`}
            >
              {getIcon(social)}
              <span className="screen-reader-text">{social}</span>
            </a>
          ))}
        </StyledSocialLinks>
        <div rel="contentinfo">
          <span><a href={`mailto:${globals.contact_email}`} title="Email us">{globals.contact_email}</a></span>
          {/*<span>&copy; {globals.copyright_text} - ALL RIGHTS RESERVED</span>*/}
          <span><a href="https://www.studiocedar.com" target="_blank" rel="noopener nofollow noreferrer">Site Credit</a></span>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer


const StyledFooter = styled.footer`

  position: relative;
  z-index: 1;
  background-color: var(--color-black);
  color: var(--color-white);
  padding: 3em 0;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    background-color: inherit;
  }
  & > div:last-of-type {
    font-size: 0.625em;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    /*
    border-top: 1px solid var(--color-gray);
    margin-top: 6em;
    padding-top: 2em;
    */
    .can-hover & a:hover,
    a:active {
      text-decoration: underline;
    }
  }

  h3 {
    font-size: 1.25em;
    font-weight: 600;
    margin: 1.5em 0 .75em;
  }

  @media ( max-width: 48em ) {
    [rel="contentinfo"] {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-top: 3em;
      span:not(:first-of-type) {
        margin-top: 0.75em;
      }
      span:last-of-type {
        margin-top: 3em;
      }
    }
  }

  @media ( min-width: 48em ) {
    & > div:last-of-type {
      width: 100%;
      display: flex;
      justify-content: space-between;
      span:not(:first-of-type)::before {
        content: " - ";
      }
    }
  }
  /*
  @media ( min-width: 60em ) {
    
    display: grid;
    grid-template: auto / 1fr 2fr;
    grid-gap: 1em 3em;
    place-items: start;
      
    h3 {
      grid-row: auto / span 2;
      margin-top: 0.6em;
    }
    
    & > div:last-of-type {
      grid-column: auto / span 2;
    }
  }
  */
`
export { StyledFooter }

const StyledSocialLinks = styled.div`
  display: flex;
  align-items: center;
  a {
    position: relative;
    display: flex;
    height: 2em;
    width: 2em;
    align-items: center;

    &:not(:last-of-type) {
      margin-right: 1.25em;
    }

    svg {
      width: 100%;

      path {
        fill: currentColor;
      }
    }
  }
  .can-hover & a:hover,
  a:active {
    opacity: 0.5;
  }

  @media ( max-width: 48em ) {
    justify-content: center;
  }

  @media ( max-width: 60em ) {
    margin-bottom: 2em;
  }
`
