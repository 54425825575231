import React from "react"
import styled, { keyframes } from "styled-components"

const ScrollDown = ({ text }) => (
  <StyledScrollDown>{text}</StyledScrollDown>
)

export default ScrollDown

const arrow = keyframes`
  0, 100% {
    transform: translate3d(0,0,0) rotate(45deg) ;
  }
  40% {
    transform: translate3d(0,100%,0) rotate(45deg) ;
  }
`

const StyledScrollDown = styled.div`
  display: block;
  margin-top: 1.5em;
  &::after {
    content: '';
    position: relative;
    display: block;
    margin: 0.5em auto 0 0;
    width: 0.75em;
    height: 0.75em;
    border-right: calc( var(--border-width) * 2) solid currentColor;
    border-bottom: calc( var(--border-width) * 2) solid currentColor;
    transform: rotate(45deg);
    animation: ${arrow} 3s ease infinite;
  }
`