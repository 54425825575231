import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Helmet from "react-helmet"
import styled from "styled-components"
import "fonts/fonts.css"
import "components/layout.scss"
import { StyledHero } from "components/hero"
import Albums from "components/albums/index"
import Footer from "components/footer"
import Wrapper from "components/wrapper"
import { GlobalsProvider } from "components/context"
import { AlbumsProvider } from "components/albums/context"


const Layout = props => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            opengraph {
              title
              image
              description
              url
            }
          }
        }
      }
    `
  )

  const dataOpengraph = data.site.siteMetadata.opengraph

  useEffect(() => {

    registerTouchables()

    if ( ! (matchMedia(`(hover: none)`).matches) ) {
      document.getElementsByTagName( 'html' )[0].classList.add(`can-hover`)
    }
  })

  return (
    <GlobalsProvider>
      <AlbumsProvider>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />
          <link rel="preconnect" href="https://fonts.gstatic.com" /> 
          <link href="https://fonts.googleapis.com/css2?family=Crimson+Pro:wght@400&display=swap" rel="stylesheet" />
          <meta property="og:title" content={dataOpengraph.title} />
          <meta property="og:image" content={dataOpengraph.image} />
          <meta property="og:description" content={dataOpengraph.description} />
          <meta property="og:url" content={dataOpengraph.url} />
        </Helmet>
        <StyledSite>
          <Wrapper zIndexMobile={3}>
            <StyledSiteContent>
              {props.children}
              <Albums />
            </StyledSiteContent>
          </Wrapper>
          <Wrapper>
            <Footer />
          </Wrapper>
        </StyledSite>
      </AlbumsProvider>
    </GlobalsProvider>
  )
}

export default Layout

const registerTouchables = () => {
  const touchables = document.querySelectorAll('button, button, a[href], label');
  let touchablesLength = touchables.length;


  while ( touchablesLength-- ) {
    touchables[touchablesLength].addEventListener('touchstart', () => {}, {passive: true});
    touchables[touchablesLength].addEventListener('touchend', () => {}, {passive: true});
  }
}

const StyledSite = styled.div`
  position: relative;
  z-index: 1;
  background-color: var(--color-background);
`
const StyledSiteContent = styled.div`
  @media ( min-width: 60em ) {
    position: relative;
    display: grid;
    grid-template: auto / 62% 38%;
    ${StyledHero} {
      grid-column: 1 / span 2;
    }
  }
`