import React from "react"
import styled from "styled-components"


const Backdrop = ({ children }) => {
  return (
      <StyledBackdrop>
        {children}
      </StyledBackdrop>
  )
}

export default Backdrop

const StyledBackdrop = styled.span`
  position: absolute;
  z-index: -1;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 100vw;
  margin-left: -50vw;
  overflow: hidden;
  .gatsby-image-wrapper {
    position: absolute !important;
    height: calc(100% + 3em);
    top: -3em;
    width: calc(100% + 4em) !important;
    max-width: none !important;
    overflow: hidden;
    transition: filter 0.8s ease;
    will-change: filter;
    img {
      object-position: right center !important;
    }
  }
  .is-backdrop-faded & .gatsby-image-wrapper {
    filter: blur(4px);
  }
  @media ( min-width: 60em ) {
    .gatsby-image-wrapper {
      height: calc(100% + 2em) !important;
      width: calc(100% + 2em) !important;
      top: -1em;
      right: -1em;
      bottom: -1em;
      left: -1em;
    }
  }
`