import React, { useEffect } from "react"
import styled from "styled-components"
import Button from "components/button"
import { useAlbums } from "components/albums/context"



const AlbumsFilters = ({ 
  setShowFilters,
  keywordSearch,
  setKeywordSearch,
  selectedGenres,
  setSelectedGenres,
}) => {

  const { genres, albumsAll, albumsReleased, albumsUpcoming, setAlbumsList } = useAlbums()

  const toggleGenre = ( genre ) => {
    if ( selectedGenres.includes( genre ) ) {
      setSelectedGenres( selectedGenres.filter( selectedGenre => selectedGenre !== genre ) )
    }
    else {
      setSelectedGenres([...selectedGenres, genre])
    }
  }
  const toggleAllGenres = () => {
    if ( selectedGenres.length === genres.length ) {
      setSelectedGenres([])
    }
    else {
      setSelectedGenres(genres)
    }
  }

  const keywordMatch = ( album, re ) => {
    return (
      album.node.title.match(re)
      ||
      album.node.artist.name.match(re)
    )
  }

  useEffect(() => {
    
    const albumHasGenre = ( genres ) => {
      if ( genres[0] &&  selectedGenres.includes(genres[0].name) ) {
        return true;
      }
      else if ( genres[1] && selectedGenres.includes(genres[1].name)) {
        return true;
      }
      return false
    }

    if ( keywordSearch.length > 0 ) {
      const re = new RegExp( keywordSearch, 'i')
      setAlbumsList({
        released: albumsAll.filter( album => album.node.released && keywordMatch(album, re) && albumHasGenre(album.node.genre) ),
        upcoming: albumsAll.filter( album => ! album.node.released && keywordMatch(album, re) && albumHasGenre(album.node.genre))
      })
    }
    else {
      setAlbumsList({
        released: albumsAll.filter( album => album.node.released && albumHasGenre(album.node.genre) ),
        upcoming: albumsAll.filter( album => ! album.node.released && albumHasGenre(album.node.genre) )
      })
    }
  }, [selectedGenres, albumsAll, keywordSearch ])

  const totalAlbums = (albumsReleased.length + albumsUpcoming.length)

  return (
    <StyledAlbumsFilters>
      <div>
        <h3>Filter Albums</h3>
        <input 
          type="search" 
          placeholder="By Artist or Album Name..." 
          autoComplete="off"
          value={keywordSearch} 
          onInput={(e) => setKeywordSearch(e.target.value)}
          onKeyUp={(e) => {
            if ( e.key === 'Enter' ) {
              setShowFilters(false)
            }
          }}
        />
        <div>
          <p>
            By Genre
            <button type="button" onClick={toggleAllGenres}>{selectedGenres.length === genres.length ? `Select none` : `Select all`}</button>
          </p>
          {genres.map(( genre ) => (
            <label key={`genre-filter-${genre}`}>
              <input type="checkbox" checked={selectedGenres.includes( genre )} onChange={(e) => toggleGenre(genre)} />
              <span>{genre}</span>
            </label>
          ))}
        </div>
        <Button type="button" onClick={() => setShowFilters(false)}>Done</Button>
        <span>{totalAlbums} album{totalAlbums !== 1 && `s`}</span>
      </div>
    </StyledAlbumsFilters>
  )
}

export default AlbumsFilters


const StyledAlbumsFilters = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.1);
  backdrop-filter: blur(3px);
  > div {
    background-color: var(--color-black);
    border-bottom: var(--border-width) solid var(--color-gray);
    padding: 1em calc(1.25 * var(--padding)) var(--padding);
  }
  h3 {
    font-size: 1.125em;
    font-weight: 600;
    color: var(--color-gray-text);
    margin: 0 0 1em;
    line-height: 1;
  }
  input[type="search"] {
    appearance: none;
    width: 100%;
    border: 0;
    background-color: transparent;
    font-family: inherit;
    font-size: inherit;
    font-weight: 600;
    color: inherit;
    outline: none;
    margin: 0;
    height: 2.25em;
    padding: 0 0.5em;
    line-height: 2.25;
    border: var(--border-width) solid rgba(255,255,255,0.1);
    border-radius: 0;
    box-shadow: inset 0 0 0.25em rgba(0,0,0,0.3);
    background-color: rgba(255,255,255,0.15);
    &::-webkit-search-cancel-button {
      display: none;
    }
  }
  div div {
    display: grid;
    grid-template: auto / 1fr 1fr;
    grid-gap: 0.75em;
    padding: calc( 0.75 * var(--padding)) 0 var(--padding);
    p {
      font-weight: 600;
      color: var(--color-gray-text);
      margin: 0;
      grid-column: 1 / span 2;
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        appearance: none;
        border: 0;
        font-size: 0.625rem;
        font-weight: 600;
        background-color: var(--color-gray-text);
        color: var(--color-black);
        padding: 0.25em 0.5em;
        .can-hover &:hover,
        &:active {
          background-color: var(--color-white);
        }
      }
    }
    input {
      position: fixed;
      left: -99999em;
    }
    label {
      cursor: pointer;
    }
    label span {
      position: relative;
      display: block;
      font-size: 0.875em;
      font-weight: 600;
      padding-left: 1.5em;
      color: var(--color-gray-text);
      &::before {
        content: '';
        position: absolute;
        font-size: 1rem;
        height: 0.75em;
        width: 0.75em;
        top: 0.25em;
        left: 0;
        border: var(--border-width) solid #666;
        box-shadow: inset 0 0 0.25em rgba(0,0,0,0.3), inset 0 0 0 0.1875em #262626;
        background-color: #262626;
      }
    }
    .can-hover & label:hover span::before,
    label:active span::before {
      border-color: #fff;
      background-color: var(--color-gray);
    }
    label input:checked ~ span,
    .can-hover & label:hover span,
    label:active span {
      color: var(--color-white);
    }
    label input:checked ~ span::before {
      box-shadow: inset 0 0 0.25em rgba(0,0,0,0.3), inset 0 0 0 0.1875em #262626;
      background-color: #fff;
    }
  }
  > div > span {
    float: right;
    font-size: 0.875em;
    font-weight: 600;
    color: var(--color-gray-text);
    margin-top: 0.75em;
  }
`