import React from "react"

const GlobalsContext = React.createContext()

function useGlobals() {
  const context = React.useContext(GlobalsContext)
  if ( ! context ) {
    throw new Error(`useGlobals must be used within an GlobalsContext`)
  }

  const [globals, setGlobals] = context
  
  const setAlbumNavOpened = ( opened ) => {
    setGlobals({...globals, albumNavOpened: opened })
  }

  return {
    albumNavOpened: globals?.albumNavOpened || false,
    setAlbumNavOpened
  }
}

function GlobalsProvider(props) {
  const [glboals, setGlobals] = React.useState()
  const value = React.useMemo(() => [glboals, setGlobals], [glboals])
  return <GlobalsContext.Provider value={value} {...props} />
}

export {
  GlobalsProvider,
  useGlobals
}