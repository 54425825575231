import React from "react"
import styled from "styled-components"

const AlbumGenres = ({ genres, slug }) => (
  <StyleAlbumGenres>
    {genres.map((genre) => (
      <li key={`${slug}-${genre.name}`}>{genre.name}</li>
    ))}
  </StyleAlbumGenres>
)

export default AlbumGenres

const StyleAlbumGenres = styled.ul`
  margin: 0;
  list-style: none;
  padding: 0;
  li {
    font-size: 0.75em;
    display: inline;
    &:not(:last-of-type)::after {
      content: ", ";
    }
  }
`