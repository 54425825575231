import React from "react"
import styled from "styled-components"

const StyledIconArrowRight = styled.svg`
    height: ${props => props.size && props.size};
    width ${props => props.size && props.size};
  padding-left: 0.125em;
  g {
    stroke: currentColor;
  }
`

const IconArrowRight = ({ size }) => (
    <StyledIconArrowRight viewBox="0 0 12 20" version="1.1" xmlns="http://www.w3.org/2000/svg" size={size || "0.875em"}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-424.000000, -905.000000)" stroke="#000000" strokeWidth="2">
                <g transform="translate(331.000000, 896.000000)">
                    <g transform="translate(97.536585, 19.000000) scale(-1, 1) translate(-97.536585, -19.000000) translate(79.000000, 0.000000)">
                        <polyline transform="translate(21.780488, 19.000000) rotate(-135.000000) translate(-21.780488, -19.000000) " points="15.7560976 12.9756098 27.804878 12.9756098 27.804878 25.0243902"></polyline>
                    </g>
                </g>
            </g>
        </g>
    </StyledIconArrowRight>
)

export default IconArrowRight