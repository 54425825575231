import React from "react"
import { Link } from "gatsby"
import { GatsbyImage  } from "gatsby-plugin-image"
import styled from "styled-components"
import { useGlobals } from "components/context"
import AlbumGenres from "components/albums/genres"
import { StyledAlbumsList } from "components/albums/listSet"
import getAlbumLink from "utils/getAlbumLink"


const AlbumListItemInfo = ({album}) => (
  <div>
    <strong>{album.artist.name}</strong>
    <span>{album.title}</span>
    <AlbumGenres genres={album.genre} slug={album.slug} />
  </div>
)
const AlbumListItemReleased = ({ album }) => {

  const { setAlbumNavOpened } = useGlobals()
  
  return (
    <StyledAlbumListItemLink
      as={Link}
      to={getAlbumLink(album)}
      onClick={() => setAlbumNavOpened(false)}
      className={album.wave==='11' ? 'is-new' : undefined}
    >
      {album.wave === '11' && (
        <AlbumItemTagWrap>
          <AlbumItemTag>New</AlbumItemTag>
        </AlbumItemTagWrap>
      )}
      <figure>
        {album.cover && (
          <GatsbyImage 
            image={album.cover.localFile.childImageSharp.gatsbyImageData} 
            alt={`${album.title} by ${album.artist.name} for the Noam Chomsky Music Project`}
          />
        )}
      </figure>
      <AlbumListItemInfo album={album} />
    </StyledAlbumListItemLink>
  )
}

const AlbumListItemUpcoming = ({ album }) => (
  <StyledAlbumListItem>
    <AlbumListItemInfo album={album} />
  </StyledAlbumListItem>
)

export { AlbumListItemReleased, AlbumListItemUpcoming }

const StyledAlbumListItem = styled.a`
  position: relative;
  display: block;
  align-items: center;
  padding: 1em 0;
  ${StyledAlbumsList} li:not(:last-of-type) & {
    border-bottom: var(--border-gray);
  }
  figure {
    background-color: var(--color-gray);
    height: 5em;
  }
  strong {
    line-height: 1.2;
  }
  span {
    font-size: 0.875em;
    font-weight: 600;
  }
  div {
    display: flex;
    flex-direction: column;
    transition: opacity 0.2s ease;
    ul {
      font-weight: 600;
      color: var(--color-gray-text);
      line-height: 1;
    }
  }
  @media ( max-width: 48em ) {
    grid-template: 1fr / 4em 1fr;
    figure {
      height: 4em;
    }
    strong {
      font-size: 0.875em;
    }
    span {
      font-size: 0.75em;
    }
  }
`
const StyledAlbumListItemLink = styled(StyledAlbumListItem)`
  display: grid;
  grid-template: 1fr / 5em 1fr;
  grid-gap: 1em;
  .can-hover &:hover > div,
  &:active > div,
  &[aria-current="page"] > div {
    opacity: 0.6;
  }
  &.is-new {
    padding-right: 1.25em;
  }
  @media ( min-width: 60em ) {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: calc( 0.375 * var(--padding) );
      left: calc( -1.25 * var(--padding));
      background-color: var(--color-background);
      clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
      transform: translate3d(-100%,0,0);
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .can-hover &:hover::before,
    &:active::before,
    &[aria-current="page"]::before {
      transform: translate3d(0,0,0);
    }
  }
`

const AlbumItemTagWrap = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
`
const AlbumItemTag = styled.b`
  position: absolute;
  z-index: 3;
  background-color: var(--color-gray);
  color: var(--color-white);
  font-size: 0.625em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  line-height: 1;
  text-transform: uppercase;
  padding: 0.5em 1em;
  width: 10em;
  text-align: center;
  top: 4.75em;
  right: -2.25em;
  transform: rotate(45deg);
  transform-origin: top right;
  @media ( max-width: 48em ) {
    top: 4.5em;
    right: -2.5em;
  }
`