import React from "react"

const AlbumsContext = React.createContext()

function useAlbums() {
  const context = React.useContext(AlbumsContext)
  if ( ! context ) {
    throw new Error(`useAlbums must be used within an AlbumsContext`)
  }

  const [albums, setAlbums] = context

  const setAlbumsAll = ( all ) => {

    const genres = []
    all.forEach(({ node }) => {
      node.genre.forEach(({ name }) => {
        if ( ! genres.includes(name) ) {
          genres.push(name)
        }
      })
    })
    genres.sort()

    setAlbums({ ...albums, 
      all: all, 
      released: all.filter( album => album.node.released ),
      upcoming: all.filter( album => ! album.node.released ),
      genres: genres 
    })
  }

  const setAlbumsList = (data) => {
    setAlbums({ ...albums, released: data.released, upcoming: data.upcoming })
  }
  const setAlbumsReleased = ( released ) => {
    setAlbums({ ...albums, released: released })
  }
  const setAlbumsUpcoming = ( upcoming ) => {
    setAlbums({ ...albums, upcoming: upcoming })
  }

  return {
    albums,
    genres: albums?.genres || [],
    albumsAll: albums?.all || [],
    albumsReleased: albums?.released || [],
    albumsUpcoming: albums?.upcoming || [],
    setAlbumsList,
    setAlbumsAll,
    setAlbumsReleased,
    setAlbumsUpcoming
  }
}

function AlbumsProvider(props) {
  const [albums, setAlbums] = React.useState()
  const value = React.useMemo(() => [albums, setAlbums], [albums])
  return <AlbumsContext.Provider value={value} {...props} />
}

export {
  AlbumsProvider,
  useAlbums
}