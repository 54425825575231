import styled, { css } from "styled-components"

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  padding: 0 8%;
  width: 100%;
  ${props => props.zIndexMobile && css`
    @media ( max-width: 60em ) {
      z-index: ${props.zIndexMobile};
    }
  `}
  @media ( min-width: 60em ) {
    margin: 0 auto;
    padding: 0 6%;
  }
  @media ( min-width: 70em ) {
    max-width: 70em;
    padding: 0 3em;
  }
`
export default Wrapper
