import React, { useEffect, useState } from "react"
import {useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import IconArrowRight from "components/icons/arrowRight"
import { useGlobals } from "components/context"
import { useAlbums } from "components/albums/context"
import { AlbumListItemReleased, AlbumListItemUpcoming } from "components/albums/listItem"
import AlbumListSet from "components/albums/listSet"
import AlbumsFilters from "components/albums/filters"
import Button from "components/button"


const Albums = () => {
  const data = useStaticQuery(
    graphql`
      query {
        albums: allDirectusAlbum(sort: {fields: artist___slug, order: ASC}, filter: {wave: {in: ["1","2","3","4","5","6","7","8","9","10","11"]}}) {
          edges {
            node {
              ...AlbumExcerpt
            }
          }
        }
      }
    `
  )

  const { albumNavOpened, setAlbumNavOpened } = useGlobals()

  const { genres, albumsAll, albumsReleased, albumsUpcoming, setAlbumsAll, setAlbumsList } = useAlbums()


  const [showFilters, setShowFilters] = useState(false)
  const [keywordSearch, setKeywordSearch] = useState('')
  const [selectedGenres, setSelectedGenres] = useState(genres)
  const [hasFilters, setHasFilters] = useState(false)
  const [released, setReleased] = useState( data.albums.edges.filter( album => album.node.released ) )
  const [upcoming, setUpcoming] = useState( data.albums.edges.filter( album => ! album.node.released ) )

  useEffect(() => {
    if ( albumsAll.length < 1 ) {
      setAlbumsAll(data.albums.edges)
    }
  })

  useEffect(() => {
    setReleased(albumsReleased)
  }, [albumsReleased])

  useEffect(() => {
    setUpcoming(albumsUpcoming)
  }, [albumsUpcoming])

  useEffect(() => {
    setSelectedGenres(genres)
  }, [genres])

  const resetFilters = () => {
    setKeywordSearch('')
    setSelectedGenres(genres)
    setAlbumsList({
      released: albumsAll.filter( album => album.node.released ), 
      upcoming: albumsAll.filter( album => ! album.node.released )
    })
  }


  useEffect(() => {
    if ( keywordSearch.length > 0 || selectedGenres.length < genres.length )  {
      setHasFilters(true)
      console.log(hasFilters)
    }
    else {
      setHasFilters(false)
    }
  }, [keywordSearch, selectedGenres, genres, hasFilters])

  return (
    <>
      <input id="navtoggle" type="checkbox" className="hidden" />
      <StyledAlbumsOpen 
        as="label"
        htmlFor="navtoggle"
        theme="dark"
        onClick={(e) => {e.preventDefault(); setAlbumNavOpened( ! albumNavOpened )}}
      >
        <span>Albums</span> <IconArrowRight />
      </StyledAlbumsOpen>
      <StyledAlbumsBackdrop className={albumNavOpened ? 'is-opened' : undefined} />
      <StyledAlbums className={albumNavOpened ? 'is-opened' : undefined}>
        <StyledAlbumsClose 
          htmlFor="navtoggle"
          onClick={(e) => {e.preventDefault(); setAlbumNavOpened( ! albumNavOpened )}}
        >
          <span>Close <IconArrowRight /></span> 
        </StyledAlbumsClose>
        <StyledFilterToggles>
          {hasFilters && (
            <button type="button" onClick={resetFilters}>Reset</button>
          )}
          <button type="button" onClick={() => setShowFilters( ! showFilters )}>Filter</button>
        </StyledFilterToggles>
        {showFilters && (
          <AlbumsFilters 
            setShowFilters={setShowFilters}
            selectedGenres={selectedGenres}
            setSelectedGenres={setSelectedGenres}
            keywordSearch={keywordSearch}
            setKeywordSearch={setKeywordSearch}
          />
        )}
        <StyledAlbumsContent>
          {released.length < 1 && upcoming.length < 1 && (
            <AlbumListSet title="No Results"></AlbumListSet>
          )}
          {released.length > 0 && (
            <AlbumListSet title="Already Released">
              {released.map(({ node }) => (
                <li key={node.slug}>
                  <AlbumListItemReleased 
                    album={node}
                  />
                </li>
              ))}
            </AlbumListSet>
          )}
          {upcoming.length > 0 && (
            <AlbumListSet title="Upcoming Releases">
              {upcoming.map(({ node }) => (
                <li key={node.slug}>
                  <AlbumListItemUpcoming 
                    album={node}
                  />
                </li>
              ))}
            </AlbumListSet>
          )}
        </StyledAlbumsContent>
      </StyledAlbums>
    </>
  )
}

export default Albums

const StyledAlbums = styled.aside`
  position: relative;
  z-index: 5;
  background-color: var(--color-black);
  color: var(--color-white);
  @media ( max-width: 60em ) {
    position: fixed;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(100vw - 3em);
    transform: translate3d(calc(100% + 3em),0,0);
    transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    input:checked ~ &,
    &.is-opened {
      transform: translate3d(0,0,0);
      transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
  @media ( min-width: 60em ) {
    position: sticky;
    top: calc(1.5 * var(--padding));
    /*margin-top: calc(2 * var(--padding));*/
    height: calc(100vh - ( 3* var(--padding)));
    margin-bottom: calc(1.5 * var(--padding));
  }
`
export { StyledAlbums }

const StyledAlbumsBackdrop = styled.div`
  display: none;
  @media ( max-width: 48em ) {
    position: fixed;
    display: block;
    z-index: 5;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0);
    backdrop-filter: blur(8px);
    transform: translate3d(100%,0,0);
    opacity: 0;
    transition: transform 0s linear 0.5s, opacity 0.3s ease 0.2s;
    input:checked ~ &,
    &.is-opened {
      transform: translate3d(0,0,0);
      opacity: 1;
      transition: transform 0s linear, opacity 0.3s ease;
    }
  }
`

const StyledAlbumsContent = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 calc(0.75 * var(--padding) ) calc(0.5 * var(--padding)) var(--padding);
  @media ( min-width: 60em ) {
    padding: 0 calc(0.75 * var(--padding)) calc(0.5 * var(--padding)) calc(1.25 * var(--padding));
  }
  &::-webkit-scrollbar {
    width: 0.625em;
  }
  &::-webkit-scrollbar-track {
    width: 0.625em;
    height: 0.625em;
    background-color: var(--color-black);
  }
  &::-webkit-scrollbar-thumb {
      height: 0.25em;
      width: 0.25em;
      background: var(--color-gray);
      border: 0.25em solid var(--color-black);
  }
`

const StyledAlbumsClose = styled.label`
  display: none;
  @media ( max-width: 60em ) {
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 100;
    top: 0;
    right: calc(100% - 1px);
    font-weight: 600;
    background-color: var(--color-black);
    border-right: var(--border-gray);
    color: var(--color-white);
    cursor: pointer;
    padding: 0 0.875em 0 1em;
    height: 100%;
    width: calc(3em + 1px);
    line-height: 2.75;
    .can-hover &:hover,
    &:active {
      background-color: var(--color-gray);
    }
    span {
      display: flex;
      align-items: center;
      transform: rotate(-90deg) translate3d(4em,-2.25em,0);
      transform-origin: right bottom;
      font-size: 0.875em;
      letter-spacing: 0.05em;
      svg {
        transform: rotate(90deg);
        margin-left: 0.5em;
      }
    }
    input:checked ~ ${StyledAlbums} & svg,
    .is-opened & svg {
      transform: rotate(90deg);
    }
  }
`

const StyledAlbumsOpen = styled(Button)`
  display: none;
  @media ( max-width: 60em ) {
    position: fixed;
    display: flex;
    align-items: center;
    z-index: 5;
    top: 0;
    right: 0;
  }
`

const StyledFilterToggles = styled.div`
  position: absolute;
  z-index: 9;
  top: 0.75rem;
  right: calc( 0.75 * var(--padding) + 0.625rem);
  button {
    position: relative;
    appearance: none;
    background-color: var(--color-white);
    border: 0;
    font-size: 0.875em;
    font-weight: 600;
    margin: 0;
    color: var(--color-black);
    padding: 0.25em 0.625em;
    .can-hover &:hover,
    &:active {
      color: var(--color-white);
      background-color: var(--color-gray);
    }
  }
  button:first-of-type:not(:last-of-type) {
    margin-right: 0.25em;
  }
`

