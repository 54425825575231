import React, { useLayoutEffect, useRef } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import Wrapper from "components/wrapper"
import ScrollDown from "components/scrollDown"
import Backdrop from "components/backdrop"


const Hero = ({ intro }) => {

  const refHero = useRef()

  function step() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const heroContent = refHero.current
    if ( heroContent && scrollTop <= (heroContent.offsetHeight * 1.5) ) {
      const threshold = 36
      const scrollTopThreshold = (scrollTop - threshold) > 0 ? (scrollTop - threshold) : 0
      const paralax = (scrollTopThreshold * .01).toFixed(2)
      const blur = ( scrollTopThreshold * .00002 ).toFixed(5)
      const rotate = (scrollTopThreshold * 0.01).toFixed(2)
      const scale = ( scrollTopThreshold * .0005 ).toFixed(4)
      heroContent.style = `--paralax: ${paralax}px; --blur: ${blur}px; --rotate: ${rotate}deg; --scale: ${scale}`
      if ( scrollTop > heroContent.offsetHeight / 2 ) {
        heroContent.classList.add('is-backdrop-faded')
      }
      else {
        heroContent.classList.remove('is-backdrop-faded')
      }
    }
    if ( heroContent ) {
      requestAnimationFrame(step)
    }
  }

  useLayoutEffect(() => {
    requestAnimationFrame(step)
    return () => {
      cancelAnimationFrame(step)
    }
  })

  const headline = 'The Noam Chomsky Music Project'
  const words = headline.split(' ')
  const characterCount = headline.replace(/ /, '').length

  function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
  }

  return (
    <StyledHero ref={refHero}>
      <Backdrop>
        <StaticImage
          src="../images/hero.jpg"
          alt=""
        />
      </Backdrop>
      <StyledHeroContent>
        <Wrapper>
          <div>
            <StyledHeadline>
              {words.map((word,i) => {
                const characters = word.split('')
                return (
                  <StyledHeadlineWord key={`${word}`}>
                    {characters.map((character) => {
                      const rand = getRandomIntInclusive(3, characterCount * 3)
                      return (
                        <StyledHeadlineLetter 
                          key={`${word}-${character}-${rand}`} 
                          style={{"--rand" : rand}}
                        >{character}</StyledHeadlineLetter>
                      )
                    })}
                  &nbsp;{i < (words.length - 2) && <br />}
                  </StyledHeadlineWord>
                )
              })}
            </StyledHeadline>
            <div>
              <p>{intro}</p>
              <ScrollDown />
            </div>
          </div>
        </Wrapper>
      </StyledHeroContent>
    </StyledHero>
  )
}

export default Hero

const StyledHero = styled.header`
  position: sticky;
  top: 0;
  z-index: -1;
  color: var(--color-white);
  padding: var(--padding) 0;
  height: 36.25em;
  &::before {
    content: '';
    position: absolute;
    background-color: var(--color-gray);
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
  }
  @media ( min-width: 60em ) {
    height: 100vh;
  }
`
export { StyledHero }

const StyledHeroContent = styled.div`
  position: absolute;
  width: 100vw;
  left: 50%;
  margin-left: -50vw;
  overflow: hidden;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media ( min-width: 60em ) {
    justify-content: center;
  }
  ${Wrapper} {
    p {
      font-family: var(--font-serif);
      font-size: 1.125em;
      line-height: 1.3;
      max-width: 22em;
    }
    > div {
      transform: translate3d(0, calc( var(--paralax) * 5), 0);
      filter: blur( calc( var(--blur) * 400 ));
      will-change: transform, filter;
    }
    @media ( max-width: 60em ) {
      > div {
        padding-bottom: calc( var(--padding));
      }
      > div > div > div {
        display: none !important;
      }
    }
    @media ( min-width: 48em ) {
      p {
        font-size: 1.25em;
        line-height: 1.5;
      }
    }
  }
`

const StyledHeadline = styled.h1`
  font-size: 2.875em;
  font-size: 10.5vw;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  margin: 0;
  @media ( min-width: 48em ) {
    font-size: 4em;
  }
`

const StyledHeadlineWord = styled.span`
  &:nth-of-type(2),
  &:nth-of-type(3) {
    font-weight: 700;
  }
`

const StyledHeadlineLetter = styled.span`
  position: relative;
  display: inline-block;
  transform: translate3d(calc( var(--paralax) * (var(--rand)) * -1), calc( var(--paralax) * (var(--rand)) * -1), 0) 
    rotateZ( calc( var(--rand) * var(--rotate)))
    rotateY(calc( var(--rand) * var(--rotate) * -1));
  filter: blur( calc( var(--blur) * (var(--rand) * 0.001 )));
  will-change: transform, filter;

  &:nth-of-type(odd) {
    transform: translate3d(calc( var(--paralax) * (var(--rand)) * 1), calc( var(--paralax) * (var(--rand)) * -1), 0) 
      rotateZ( calc( var(--rand) * var(--rotate) * -1))
      rotateY( calc( var(--rand) * var(--rotate) * 1));
  }

  &:nth-of-type(3n),
  ${StyledHeadlineWord}:nth-of-type(odd) span:nth-of-type(4n) {
    transform: translate3d(calc( var(--paralax) * (var(--rand)) * 2), calc( var(--paralax) * (var(--rand)) * -1), 0) 
      rotate( calc( var(--rand) * var(--rotate) * -1))
      scale( calc( 1 - (var(--rand) * var(--scale) / 10)));
    filter: blur( calc( var(--blur) * (var(--rand) * 0.01 )));
  }

  @media ( max-width: 60em ) {=
    position: relative;
    display: inline-block;
    transform: translate3d(calc( var(--paralax) * (var(--rand)) * -0.5), calc( var(--paralax) * (var(--rand)) * -2), 0) 
      rotateZ( calc( var(--rand) * var(--rotate)))
      rotateY(calc( var(--rand) * var(--rotate) * -1));
    filter: blur( calc( var(--blur) * (var(--rand) * 0.001 )));

    &:nth-of-type(odd) {
      transform: translate3d(calc( var(--paralax) * (var(--rand)) * 0.5), calc( var(--paralax) * (var(--rand)) * -2), 0) 
        rotateZ( calc( var(--rand) * var(--rotate) * -1))
        rotateY( calc( var(--rand) * var(--rotate) * 1));
    }
    
    &:nth-of-type(4n+1) {
      transform: translate3d(calc( var(--paralax) * (var(--rand)) * 1), calc( var(--paralax) * (var(--rand)) * -2), 0) 
        rotateZ( calc( var(--rand) * var(--rotate) * 1));
        rotateY( calc( var(--rand) * var(--rotate) * 1));
    }

    &:nth-of-type(3n),
    ${StyledHeadlineWord}:nth-of-type(odd) span:nth-of-type(4n) {
      transform: translate3d(calc( var(--paralax) * (var(--rand)) * 1), calc( var(--paralax) * (var(--rand)) * -2), 0) 
        rotate( calc( var(--rand) * var(--rotate) * -1))
        scale( calc( 1 - (var(--rand) * var(--scale) / 10)));
      filter: blur( calc( var(--blur) * (var(--rand) * 0.01 )));
    }
  }
  
`