import React from "react"
import styled from "styled-components"

const AlbumListSet = ({ title, children }) => (
  <StyledAlbumsSet>
      <h2>
        <strong>{title}</strong>
      </h2>
      <StyledAlbumsList>
        {children}
    </StyledAlbumsList>
  </StyledAlbumsSet>
)
export default AlbumListSet


const StyledAlbumsSet = styled.section`
  h2 {
    position: sticky;
    z-index: 5;
    top: 0;
    font-size: 1em;
    margin: 0;
    background-color: var(--color-black);
    border-bottom: var(--border-gray);
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    padding: 1em 0;
    @media ( min-width: 60em ) {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(-1.25 * var(--padding));
        width: calc(1.25 * var(--padding));
        background-color: inherit;
      }
    }
    strong {
      font-size: 1.125em;
      font-weight: 600;
      color: var(--color-gray-text);
    }
  }
`

const StyledAlbumsList = styled.ol`
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
`
export { StyledAlbumsList }
